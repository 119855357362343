<script>
import DashboardService from "@/services/dashboard";
import {DataListBasedPanels} from "@/services/dashboard";
import {isUUID} from "@/utils";
import {mqttTopic} from "@/services/equipment.js";
import {isEqual, debounce} from "lodash";
import NamedQueryManager from "@/components/statistics/named-query-manager";
export default {
  name: "DashboardEquipmentBase",
  props: {
    equipmentId: {
      type: [Number, String], // TODO by using strings we will allow more than one equipment in near future (1,2,...):
      required: false,
      default: () => 0
    },
    mode: {
      type: String,
      required: false,
      default: () => "viewer"
    },
    panelName: {
      type: String,
      required: false,
      default: () => ""
    },
    screenId: {
      type: [Number, String],
      required: false,
      default: () => ""
    }
  },
  data() {
    return {
      dashboardError: "",
      source: null,
      mDisplay: null,
      resourcesLoading: false,
      tmpScreenId: null,
      forceUpdate: false
    };
  },
  computed: {
    display: {
      set(value) {
        if (this.mDisplay && !value && this._qm) {
          this._qm = this._qm.destroy();
        }
        this.$set(this, "mDisplay", value);
        if (!this._qm && value) {
          this._qm = new NamedQueryManager(this, value);
        }
      },
      get() {
        return this.mDisplay;
      }
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"] || [];
    },
    deviceList() {
      return this.$store.getters["dashboard/deviceList"] || [];
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    },
    equipment() {
      return this.equipmentId
        ? this.connectorList.find(
            ({id}) => id && parseInt(id) === parseInt(this.equipmentId)
          )
        : null;
    },
    equipmentReady() {
      return this.equipmentId && this.equipment
        ? true
        : !this.equipmentId ||
            (this.mode == "editor" && this.$store.getters["isReady"]);
    },
    isStoreInitialized() {
      return this.$store.getters["dashboard/isReady"];
    },
    isReady() {
      return this.display &&
        this.$store.getters["user/isSettingsLoaded"] &&
        this.equipmentReady
        ? true
        : false;
    },
    selectedScreenId() {
      if (this.screenState !== "ready" || !this.equipmentReady) return null;
      let screenId =
        this.tmpScreenId ||
        (this.screenId ? parseInt(this.screenId) : this.equipment?.screen_id);
      if (
        screenId &&
        (screenId < 0 ||
          (screenId &&
            (this.screenList || []).some(
              ({id}) => parseInt(id) == parseInt(screenId)
            )))
      ) {
        return screenId;
      } else {
        return (this.screenList || []).find((i) => "default" in i && i.default)
          .id;
      }
    },
    dashboardMode() {
      return this.$store.getters["dashboard/mode"];
    },
    media() {
      if (this.$store.getters["dashboard/mode"] == "editor") return "screen";
      return (
        this?.$route?.query?.media ||
        (this.equipmentTemplate() && this.equipmentTemplate()?.media) ||
        "screen"
      );
    },
    refreshInterval() {
      // !IMPORTANT NOTE!
      // The legacy value of this property was stored as 5m (300000), which
      // are now a too high standard. Therefore, 5min will be set to 300001ms and legacy
      // value will be replaced by the default one (30s)
      const _default = this?.$root?.config?.pooling_interval?.value || 30000;
      if (!this.display) return _default;
      return this.display.refreshInterval == 300000
        ? _default
        : this.display.refreshInterval;
    },
    qstr() {
      let result = {...(this.$route.query || {})};
      if ("_cdim" in this.$route.query) {
        result = {
          ...JSON.parse(localStorage.getItem("_cdim") || "{}"),
          ...result
        };
      }
      for (var p in result) {
        if (result[p] && /^\$\(.*$/.test(result[p])) {
          result[p] = this.$root.$formatter.format({
            template: result[p]
          });
        }
      }
      return result;
    },
    routePath() {
      return this.$route.path;
    },
    deviceId() {
      return parseInt(this?.qstr?.device_id || this?.qstr?.deviceId || 0);
    },
    screenState() {
      return this.$store.getters["dashboard/hasPrivateScreensLoaded"]
        ? "ready"
        : "loading";
    },
    screenList() {
      return this.$store.getters["dashboard/activeScreens"];
    },
    screen() {
      return this.screenList.find(
        ({id}) => parseInt(id) == parseInt(this.selectedScreenId)
      );
    },
    leaveConfirmation() {
      let cfg = this?.display?.on_leave_confirmation || null;
      if (this.mode == "viewer" && cfg?.enabled) {
        // todo: validate conditions
        if (
          cfg.condition == "pending_changes" &&
          !this.$store.getters["dashboard/hasPendingChanges"]
        ) {
          return null;
        }
        return cfg;
      }
      return null;
    }
  },
  watch: {
    connectorList: {
      handler(n, o) {
        if (!this.equipmentId) return;
        if (!this.equipmentCheck && (n || []).length && !(o || []).length) {
          this.equipmentCheck = true;
          if (!this.equipment) {
            this.dashboardError = "equipment_not_found";
          }
        }
      },
      immediate: true
    },
    equipmentId: {
      immediate: true,
      handler(n, o) {
        if (n) this.$store.dispatch("dashboard/setDashboardEquipmentId", n);
        else if (o)
          this.$store.dispatch("dashboard/setDashboardEquipmentId", null);
      }
    },
    equipment: {
      handler(n, o) {
        if ((n && !o) || (n && o && !isEqual(n.id, o.id))) {
          this.setup();
        }
      },
      immediate: true
    },
    isStoreInitialized: {
      handler(n) {
        if (n && this.equipmentReady) {
          if (this.equipmentTemplate()) {
            if (!this.isLatestTemplate()) {
              this.fetchDashboard();
              return;
            }
            this.setupDataList();
          } else {
            this.validateTemplate();
          }
        } else {
          this.display = null;
        }
      },
      immediate: true
    },
    screenState: {
      async handler(state) {
        if (state == "ready") {
          if (this.screen) {
            if (this.mode == "editor") {
              await this.initDraft(this.selectedScreenId);
              if (this.equipmentTemplate()) this.setupDataList();
              else this.validateTemplate();
            } else {
              if (this.equipmentTemplate()) {
                this.setupDataList();
              } else {
                this.fetchDashboard().then((template) => {
                  if (template && !this.source) {
                    this.setupDataList();
                  }
                });
              }
            }
          } else if (this.equipmentReady) {
            this.validateTemplate();
          } else if (this.$route?.query?.tpl == "draft") {
            // this.fetchDashboard().then((template) => {
            //       if (template && !this.source) {
            //         this.setupDataList();
            //       }
            //     });
          }
        }
      },
      immediate: true
    },
    async selectedScreenId(id) {
      if (id) {
        let prv = this.$store.getters["dashboard/dashboardScreenId"] || "";
        if (prv !== "" && prv != id && this.display) {
          if (!this.deviceId && (this.$store.getters["deviceId"] || 0)) {
            this.$store.commit("SET_DEVICE_ID", "");
            this.forceUpdate = true;
          }
          this.display = null;
        }
        this.setDashboardScreen(id);
        if (!this.screen) {
          return;
        } else {
          if (this.mode == "editor") {
            await this.initDraft(this.selectedScreenId);
            if (this.equipmentTemplate()) this.setupDataList();
            else this.validateTemplate();
          } else {
            if (this.equipmentTemplate()) {
              if (!this.display && !this.resourcesLoading) {
                if (!this.isLatestTemplate()) {
                  this.fetchDashboard();
                  return;
                }
                this.setupDataList();
              }
            } else {
              this.fetchDashboard();
            }
          }
        }
      }
    },
    equipmentReady(n) {
      if (n && this.equipmentTemplate() && !this.isLatestTemplate()) {
        this.fetchDashboard();
        return;
      }
      if (!n) return;
      this.$nextTick(() => {
        if (this.equipment) {
          if (this.equipmentTemplate()) {
            if (!this.isLatestTemplate()) {
              this.fetchDashboard();
              return;
            }
            if (!this.display && !this.resourcesLoading) {
              this.setupDataList();
            }
          }
        } else {
          if (!this.selectedScreenId) {
            if (
              (this.connectorList || []).length &&
              this.equipmentTemplate() &&
              !this.display &&
              !this.resourcesLoading
            ) {
              this.setupDataList();
            } else {
              this.validateTemplate();
            }
          }
        }
      });
    },
    isReady(n, o) {
      if (!o && n && !this.dashboardError) {
        if (!this.hasPermission) {
          this.dashboardError =
            "you_do_not_have_access_to_the_requested_resource";
          return;
        }
      }
    },
    $route(n, o) {
      if (this.deviceId) {
        if ((this.$store.getters["deviceId"] || 0) != this.deviceId) {
          this.$store.commit("SET_DEVICE_ID", this.deviceId);
        }
      } else {
        this.$store.commit("SET_DEVICE_ID", "");
      }
      if (n && (!o || (n.path == o.path && n.fullPath != o.fullPath))) {
        // only query parameters were changed - just update the current template
        this.setupDataList();
      }
    },
    leaveConfirmation: {
      handler(n, o) {
        if (n && !o) {
          // if (!this._beforeUnload) {
          //   this._beforeUnload = (e) => {
          //     e.preventDefault();
          //     e.returnValue = true;
          //   };
          //   window.addEventListener("beforeunload", this._beforeUnload);
          // }
          this.enableUnloadListener(true);
        } else if (o && !n) {
          this.enableUnloadListener(false);
          // window.removeEventListener("beforeunload", this._beforeUnload);
        }
      },
      immediate: true
    }
  },
  methods: {
    async initDraft(screenId) {
      this.display = null;
      return this.$store.dispatch("dashboard/initDraft", screenId);
    },
    async createNewSession() {
      return this.$store.dispatch("dashboard/createNewSession");
    },
    async resetSession() {
      return this.$store.dispatch("dashboard/resetSession");
    },
    async refresh() {
      return this.$store.dispatch("dashboard/refresh");
    },
    async onResourcesReady() {
      // Might be reimplemented
    },
    getEquipment(equipmentId) {
      return this.connectorList?.find?.(({id}) => id == equipmentId) || null;
    },
    equipmentTemplate() {
      return this.mode == "editor"
        ? this.$store.getters["dashboard/draft"]?.template
        : this.$store.getters["dashboard/template"](this.selectedScreenId);
    },
    setupDraft() {
      return new Promise((resolve) => {
        if (!this.screenId) {
          resolve(null);
          return;
        }
        let draft = this.$store.getters["dashboard/draft"] || null;
        if (draft && draft.screenId == this.screenId && draft.template) {
          this.$store.commit("dashboard/SET_TEMPLATE", {
            id: this.screenId,
            data: draft.template
          });
          resolve(draft.template);
        } else {
          this.$store
            .dispatch("dashboard/initDraft", this.screenId)
            .then(() => {
              draft = this.$store.getters["dashboard/draft"] || null;
              if (draft && draft.screenId == this.screenId && draft.template) {
                this.$store.commit("dashboard/SET_TEMPLATE", {
                  id: this.screenId,
                  data: draft.template
                });
                resolve(draft.template);
              } else {
                resolve(null);
              }
            });
        }
      });
    },
    fetchDashboard() {
      if (
        this.mode != "editor" &&
        this.screenId &&
        this.$route?.query?.tpl == "draft"
      ) {
        return this.setupDraft();
      } else {
        return this.$store.dispatch(
          "dashboard/fetchTemplate",
          this.selectedScreenId
        );
      }
    },
    requireDataListInitialization() {
      if (this.resourcesLoading) return false;
      if (!this.dataList.length) return true;
      const resourceIds = this?.source?.resourceIds || [];
      const referenceIds = this?.source?.referenceIds || [];
      const cId = parseInt(this.equipmentId || 0);
      const dId = parseInt(this.deviceId || 0);
      const nResourceIds = resourceIds.length
        ? resourceIds.filter((i) => {
            if (i.resource == "data" && i.id) {
              return this.dataList.some(
                ({id}) => parseInt(id) == parseInt(i.id)
              );
            }
            return false;
          }).length
        : -1;
      const nReferenceIds = referenceIds.length
        ? referenceIds.filter((i) => {
            if (i.resource == "data" && i.id) {
              return this.dataList.some((data) => {
                if (i.id.split("/").slice(-1)[0] !== data.reference_id)
                  return false;
                if (cId && parseInt(data.clp_id) !== cId) return false;
                if (dId && parseInt(data?.device?.id) !== dId) return false;
                return true;
              });
            }
            return false;
          }).length
        : -1;
      return !(
        (nResourceIds == -1 || nResourceIds == resourceIds.length) &&
        (nReferenceIds == -1 || nReferenceIds == referenceIds.length)
      );
    },
    fetchResourceList() {
      if (
        (!this?.source?.resourceIds.length &&
          !this?.source?.referenceIds?.length) ||
        this?.qstr.device_id ||
        this?.qstr.deviceId
      ) {
        return this.fetchConnectorDataList(this.forceUpdate);
      }
      this.resourcesLoading = true;
      return this.$store
        .dispatch("dashboard/fetchResourceList", {
          source: {
            resourceIds: this.source.resourceIds,
            referenceIds: this.$utils.distinct(
              (this.source.referenceIds || []).map((i) => ({
                ...i,
                id: (i?.id || "").split("/").slice(-1)[0] // only the data part
              }))
            ),
            connectorId: this.source.names.length ? this.equipmentId : null
          },
          forceUpdate: this.forceUpdate,
          once: true
        })
        .then((r) => {
          this.forceUpdate = false;
          this.resourcesLoading = false;
          return r;
        });
    },
    fetchConnectorDataList() {
      if (this.resourcesLoading) {
        return new Promise((resolve) => {
          resolve();
        });
      }
      this.resourcesLoading = true;
      let query = {
        resource: "data",
        connectorId: this.equipmentId || null,
        forceUpdate: true,
        once: true
      };
      let deviceId = this?.qstr.device_id || this?.qstr.deviceId || "";
      if (deviceId) {
        query.device_id = deviceId;
      }
      return this.$store
        .dispatch("dashboard/fetchResourcesFrom", query)
        .then((r) => {
          this.resourcesLoading = false;
          return r;
        });
    },
    setDisplay() {
      if (!this.equipmentTemplate()) {
        console.log("setDisplay - no template");
        return;
      }
      let deviceId = parseInt(
        this.qstr.device_id ||
          this.qstr.deviceId ||
          this.$store.getters["deviceId"] ||
          0
      );
      let data = {
        data_id: {
          ...this.source.referenceIds.reduce((obj, {id, connectorId}) => {
            obj[id] =
              this.dataList.find((data) => {
                if (deviceId) {
                  return (
                    parseInt(data.device.id) == parseInt(deviceId) &&
                    data.reference_id == id.split("/").slice(-1)[0]
                  );
                } else {
                  return (
                    parseInt(data.device.connector.id) ==
                      parseInt(connectorId) &&
                    ((id || "").indexOf("/") > 0
                      ? `${data.device.reference_id}/${data.reference_id}`
                      : `${data.reference_id}`) == id
                  );
                }
              })?.id ||
              id ||
              ""; // default id avoids the murdering of the data_id with an undefined value
            return obj;
          }, {}),
          ...this.source.names.reduce((obj, name) => {
            let data = this.dataList.find(
              (data) =>
                (data.name == name ||
                  `${data.device.name}.${data.name}` == name) &&
                data.device.connector.id == this.equipmentId
            );
            if (data) obj[name] = data.id;
            return obj;
          }, {})
        }
      };
      let display = new DashboardService().renderDashboardConfiguration(
        this.equipmentTemplate(),
        data
      );
      if (this.mode == "editor") {
        this.$store
          .dispatch("dashboard/initEditor", {
            screenId: this.screenId,
            template: display,
            panelName: this.panelName || ""
          })
          .then(() => {
            this.display = this.$store.getters["dashboard/draft"].template;
          });
      } else {
        if (
          deviceId &&
          parseInt(this.$store.getters["deviceId"]) != parseInt(deviceId)
        ) {
          this.$store.commit("SET_DEVICE_ID", deviceId);
        }
        this.display = display;
        this.$store.dispatch("dashboard/setMode", this.mode);
        this.$store.dispatch("dashboard/expand", null);
      }
      this.$root.$emit("dashboardReady");
    },
    setupDataList() {
      let template = this.equipmentTemplate();
      if (!template) return;
      if (this.resourcesLoading) return;
      if ("_cdim" in this.$route.query) {
        this.parseActionParams(template, this.qstr);
      }
      this.source = this.getDataIds(template);
      this.onResourcesReady();
      if (this.requireDataListInitialization()) {
        this.fetchResourceList().then(() => {
          this.setDisplay();
        });
      } else {
        this.$nextTick(() => {
          this.setDisplay();
        });
      }
    },
    parseActionParams(template, params) {
      (template?.panels || []).forEach((panel) => {
        if (panel.template in DataListBasedPanels) {
          DataListBasedPanels[panel.template].parser(
            panel,
            params,
            this.dataList
          );
        }
      });
    },
    getDataIds(template, recursive = false) {
      let resourceIds = {},
        referenceIds = {},
        names = {};
      for (let prop in template) {
        if (typeof template[prop] == "object") {
          let {
            resourceIds: reId,
            referenceIds: refId,
            names: n
          } = this.getDataIds(template[prop], true);
          resourceIds = {...resourceIds, ...reId};
          referenceIds = {...referenceIds, ...refId};
          names = {...names, ...n};
        } else if (prop == "data_id" || isUUID(template[prop])) {
          if (isUUID(`${template[prop]}`.split("/")[0])) {
            let refId = `${template[prop]}`.split("/")[0];
            let lst = this.deviceList.filter(
              ({id, reference_id, connector}) =>
                reference_id === refId &&
                connector &&
                !connector.base_model &&
                (!this.deviceId || parseInt(id) == parseInt(this.deviceId))
            );
            // Virtual devices might share same reference_ids among connector instances, therefore
            // data traceback connector is only possible for collector devices and simple connectors
            if (
              lst.length == 1 &&
              (!lst[0].connector.base_model_id ||
                !lst[0].data_collector_device_id ||
                lst[0].data_collector_device_id == lst[0].id)
            ) {
              referenceIds[template[prop]] = {
                connId: parseInt(lst[0].connector_id || lst[0].connector.id),
                resource: "data"
              };
            } else {
              referenceIds[template[prop]] =
                typeof template.connector_id == "number" &&
                this.mode == "editor"
                  ? {
                      connId: template.connector_id,
                      resource: "data"
                    }
                  : {connId: true, resource: "data"};
            }
          } else if (typeof template[prop] == "number") {
            resourceIds[template[prop]] = "data";
          } else if (
            typeof template[prop] == "string" &&
            template[prop].match(/connector_(\d+)_.+/)
          ) {
            resourceIds[template[prop].match(/connector_(\d+)_.+/)[1]] =
              "connector";
          } else if (
            typeof template[prop] == "string" &&
            template[prop].match(/^device_(\w+)_.+/)
          ) {
            let id = template[prop].split("_")[1];
            isUUID(id)
              ? (referenceIds[id] = {
                  connId: true,
                  resource: "device"
                })
              : !isNaN(Number(id))
              ? (resourceIds[id] = "device")
              : null;
          } else if (
            typeof template[prop] == "string" &&
            !template[prop].includes("connector") &&
            !template[prop].includes("device") &&
            template[prop]
          ) {
            names[template[prop]] = true;
          }
        }
      }

      if (!recursive) {
        resourceIds = Object.keys(resourceIds).map((id) => ({
          id: parseInt(id),
          resource: resourceIds[id]
        }));
        names = Object.keys(names).map((name) => name);
        referenceIds = Object.keys(referenceIds).map((id) => ({
          id,
          connectorId:
            typeof referenceIds[id].connId == "number"
              ? referenceIds[id].connId
              : this.equipmentId,
          resource: referenceIds[id].resource
        }));
      }
      return {resourceIds, referenceIds, names};
    },
    async setup() {
      let template = this.equipmentTemplate();
      if (this.mode == "editor") {
        if (template) {
          this.setupDataList();
        } else if (!this.screen) {
          return;
        } else {
          await this.initDraft(this.selectedScreenId);
          if (this.equipmentTemplate()) this.setupDataList();
          else this.validateTemplate();
        }
      } else {
        if (!this.screen) {
          return;
        } else if (!template) {
          this.fetchDashboard();
        } else {
          if (this.isLatestTemplate()) {
            this.setupDataList();
          } else {
            // it requires update once linked screens were updated
            this.fetchDashboard();
          }
        }
      }
    },
    refreshDashboard(connId, skipConnectionStatus) {
      if (this.mode == "editor") {
        this.display = null;
        this.setup();
      }
      let cid = connId || this.equipmentId;
      let lst = cid ? [cid] : [];
      let tasks = [];
      if (this.source) {
        let contract_id = this.$store.getters["user/contract"]?.id;
        lst = (this?.source?.referenceIds || []).reduce(
          (lst, {connectorId, resource}) => {
            return !connectorId ||
              resource != "data" ||
              lst.includes(parseInt(connectorId))
              ? lst
              : lst.concat([connectorId]);
          },
          lst
        );
        lst = (this?.source?.resourceIds || []).reduce((lst, item) => {
          if (!item || !item.id || item.resource != "data") return lst;
          let connectorId = this.dataList.find(
            ({id}) => parseInt(id) == parseInt(item.id)
          )?.clp_id;
          return !connectorId || lst.includes(parseInt(connectorId))
            ? lst
            : lst.concat([parseInt(connectorId)]);
        }, lst);
        if (lst.length) {
          tasks = lst.map((connectorId) =>
            this.$store.dispatch("dashboard/fetchDataSamples", {
              connector_id: connectorId,
              contract_id: contract_id
            })
          );
        }
      }
      if (this.media == "print") {
        Promise.all(tasks);
        return;
      }
      tasks.push(
        this.$store.dispatch("dashboard/fetchResourcesState", {
          connectorIdList: lst,
          skipConnectionStatus: skipConnectionStatus ? true : false
        })
      );
      Promise.all(tasks).then(() => {
        this.$root.$emit("refreshDone");
      });
    },
    setDashboardEquipment(id) {
      this.$store.commit("dashboard/SET_DASHBOARD_EQUIPMENT_ID", id);
    },
    setDashboardScreen(id) {
      this.$store.commit("dashboard/SET_DASHBOARD_SCREEN_ID", id);
    },
    isLatestTemplate() {
      let template = this.equipmentTemplate();
      if (template) {
        if (Object?.keys(template?.linkedPanels || {}).length) {
          let screen = null;
          for (var k in template?.linkedPanels) {
            screen =
              (this.$store.getters["dashboard/screens"] || []).find(
                ({id}) => template.linkedPanels[k].screenId == id
              ) || null;
            if (screen && screen.etag != template.linkedPanels[k].etag) {
              return false;
            }
          }
        }
        return true;
      }
      return false;
    },
    validateTemplate() {
      if (this._validateTemplateTimer) {
        clearTimeout(this._validateTemplateTimer);
        this._validateTemplateTimer = null;
      }
      this._validateTemplateTimer = setTimeout(
        () => {
          clearTimeout(this._validateTemplateTimer);
          this._validateTemplateTimer = null;
          if (this.isReady && !this.equipmentTemplate()) {
            this.dashboardError = "configured_screen_file_not_found";
          }
        },
        1000,
        this
      );
    },
    enableMQTT(option) {
      if (option) {
        if (this.onMQTTStatus) {
          this._onMQTTStatus = ($e) =>
            this.onMQTTStatus && this.onMQTTStatus($e.detail);
          this.$rt.addListener("mqtt:status", this._onMQTTStatus);
        }
        if (this.onMQTTMessage) {
          this._onMQTTMessage = ($e) =>
            this.onMQTTMessage && this.onMQTTMessage($e.detail);
          this.$rt.addListener("mqtt:message", this._onMQTTMessage);
        }
      } else {
        if (this._onMQTTStatus)
          this.$rt.removeListener("mqtt:status", this._onMQTTStatus);
        if (this._onMQTTMessage)
          this.$rt.removeListener("mqtt:message", this._onMQTTMessage);
      }
    },
    isMQTTAvailable(connector) {
      return (
        connector &&
        (connector?.protocol?.is_mqtt_protocol ||
          this?.$root?.config?.mqtt?.modbus_enabled) &&
        connector.mqtt_topic_prefix
      );
    },
    parseMQTTConnectorTopics() {
      this.mqttConnectorTopics = null; // non reactive
      if (
        this.mode == "editor" ||
        !this.source ||
        !(
          this.$root.$http.options.config?.mqtt?.websocket?.host &&
          this.$root.$http.options.config?.mqtt?.websocket?.port &&
          (this.$root.$http.options.config?.mqtt?.enabled ?? true)
        )
      )
        return;
      let topics = {};
      let instanceIds = [];
      if (this.isMQTTAvailable(this.equipment)) {
        instanceIds.push(parseInt(this?.equipment.id));
      }
      instanceIds = instanceIds.concat(
        Object.values(this?.source?.models || {}).reduce(
          (p, c) => p.concat((c || []).map((id) => parseInt(id))),
          instanceIds
        )
      );

      let dataIds = this?.source?.resourceIds.map(({id}) => parseInt(id));
      this.dataList.forEach((data) => {
        if (
          dataIds.indexOf(parseInt(data.id)) >= 0 &&
          this.isMQTTAvailable(data.device?.connector) &&
          instanceIds.indexOf(parseInt(data.device?.connector?.id)) == -1
        ) {
          instanceIds.push(parseInt(data.device.connector.id));
        }
      });
      instanceIds = this.$utils.distinct(instanceIds);
      this.connectorList
        .filter(
          ({id, mqtt_topic_prefix}) =>
            instanceIds.indexOf(parseInt(id)) >= 0 && mqtt_topic_prefix
        )
        .forEach(({id, mqtt_topic_prefix}) => {
          topics[mqtt_topic_prefix] = id;
        });

      this.mqttConnectorTopics = Object.keys(topics).length ? topics : null;
    },
    onMQTTMessage($event) {
      if (this.$rt.mqtt.status !== "CONNECTED") return;
      let entry;
      let topic = $event.info.destinationName.replace(
        /\/(connector_state|alarm_state|completed_data_acquisition|completed_data_write_cycle)/g,
        ""
      );
      let data = (this.dataList || []).find((i) => mqttTopic(i) == topic);
      let connector = this.connectorList.find(
        ({mqtt_topic_prefix}) => mqtt_topic_prefix == topic
      );
      if (data) {
        // console.log(`${topic} ${JSON.stringify(msg)}`);
        if (/alarm_state/.test($event.info.destinationName)) {
          entry = {
            id: $event.msg.id,
            ...$event.msg
          };
          entry.last_transition_at = new Date(
            $event.msg?.last_transition_at || null
          ).toISOString();
          this.$store.dispatch("dashboard/setAlarmValue", entry);
        } else {
          // console.log(data);
          entry = {
            id: data.id,
            value: $event.msg.value
          };
          if ($event.msg?.timestamp) {
            entry.date_time = new Date($event.msg?.timestamp).toISOString();
            entry.restore = {
              id: data.id,
              date_time: entry.date_time,
              value: entry.value
            };
          }
          this.$store.commit("dashboard/SET_DATA_VALUE", entry);
        }
      } else if (/connector_state/.test($event.info.destinationName)) {
        if (connector) {
          entry = {
            id: connector.id,
            ...$event.msg
          };
          this.$store.dispatch("dashboard/setConnectorValue", entry);
        }
      } else if (
        /completed_data_acquisition/.test($event.info.destinationName)
      ) {
        // it is only valid for modbus connectors
        if (!connector || connector?.protocol?.is_mqtt_protocol) return;
        // it skips connection status - since it will be handle by a different topic
        // console.log("completed_data_acquisition");
        this.onDataArquisition(connector.id);
      } else if (
        /completed_data_write_cycle/.test($event.info.destinationName)
      ) {
        // it is only valid for modbus connectors
        if (!connector || connector?.protocol?.is_mqtt_protocol) return;
        // console.log("completed_data_write_cycle");
        this.$root.$emit("data:sync", {data_ids: $event?.msg?.data_ids || []});
      }
    },
    confirmDiscardChanges() {
      return !this.leaveConfirmation || this.$swal(this.leaveConfirmation);
    },
    enableUnloadListener(opt) {
      if (opt) {
        if (!this._beforeUnload) {
          this._beforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = true;
          };
          window.addEventListener("beforeunload", this._beforeUnload);
        }
      } else if (this._beforeUnload) {
        window.removeEventListener("beforeunload", this._beforeUnload);
      }
    }
  },
  async beforeRouteUpdate(to, from, next) {
    // this hook is called when route.path is the same but query parameters (or props) are changed
    if (await this.confirmDiscardChanges()) {
      next();
    } else {
      next(false);
    }
  },
  async beforeRouteLeave(to, from, next) {
    // this hook is called when route.path is changed
    this.enableUnloadListener(false);
    if (await this.confirmDiscardChanges()) {
      next();
    } else {
      next(false);
    }
    // console.log("beforeRouteLeave");next();
  },
  beforeCreate() {
    this.$store.commit("SET_DEVICE_ID", "");
    this.$store.commit("dashboard/CREATE_SESSION");
    this.onDataArquisition = debounce((connectorId) => {
      this.refreshDashboard(connectorId, true);
    }, 200);
  },
  created() {
    this.enableUnloadListener(false);
    if (
      (this.connectorList || []).length &&
      this.equipmentId &&
      this.mode != "editor"
    ) {
      this.$store.dispatch("dashboard/fetchDevicesState", {
        connectorIdList: [this.equipmentId]
      });
    }
    if (this.selectedScreenId) {
      this.setDashboardScreen(this.selectedScreenId);
      if (!this.equipmentId) {
        this.setup();
      }
    }
    this.$emit("equipmentChanged", this.equipmentId);
    this.$root.$on("refreshPage", this.refreshDashboard);
  },
  beforeDestroy() {
    this.enableUnloadListener(false);
    if (this._qm) {
      this._qm.destroy();
      this._qm = null;
    }
    this.$store.commit("SET_DEVICE_ID", "");
    this.$root.$off("refreshPage", this.refreshDashboard);
    this.resetSession();
  }
};
</script>
