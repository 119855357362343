import Vue from "vue";
import { createHistory, destroyHistory } from "@/modules/history.js";
import { isEqual, debounce } from "lodash";

const Component = {
  props: {
    display: {
      type: Object,
      default: null,
      required: true
    },
    config: {
      type: Object,
      default: null,
      required: true
    }
  },
  data() {
    return {
      ready: false
    };
  },
  computed: {
    mode() {
      return this.$store.getters["dashboard/mode"];
    },
    interval() {
      return this.$store.getters["history/interval"] || null;
    },
    equipmentDataList() {
      return this.$store.getters["dashboard/dataList"];
    },
    namespace() {
      return this?.config?.name || "";
    },
    columns() {
      return this?.config?.dataList || [] || [];
    },
    dataIdList() {
      return this.$utils
        .distinct(this.columns || [])
        .map(({ data_id }) => parseInt(data_id));
    },
    dataList() {
      return this.$utils.distinct(
        (this.equipmentDataList || []).filter(
          ({ id }) => this.dataIdList.indexOf(parseInt(id)) >= 0
        )
      );
    },
    groupByExpression() {
      return this.config.groupByExpression;
    },
    history() {
      return this.$store.getters[`${this.namespace}/aggregatedEntries`] || null;
    },
    isLoaded() {
      return this.config && this.dataList.length ? true : false;
    },
    state() {
      return this.$store.getters[`${this.namespace}/ready`];
    }
  },
  watch: {
    isLoaded: {
      handler(n, o) {
        if (!n || n == o) return;
        this.setup();
      },
      immediate: true
    },
    interval: {
      handler(n, o) {
        if ((!n && isEqual(n, o)) || !this._onFilterChange) return;
        this._onFilterChange();
      }
    }
  },
  methods: {
    onFilterChanged() {
      if (!this.ready || !this.interval) return;
      if (this.$store.hasModule(this.namespace)) {
        this.$store.dispatch(`${this.namespace}/setInterval`, this.interval);
        this.fetch();
      }
    },
    fetch() {
      if (!this.ready || this.mode == "editor" || !this.dataIdList.length)
        return;
      if (this.$store.hasModule(this.namespace) && !this.$store.getters[`${this.namespace}/busy`]) {
        this.$store.dispatch(`${this.namespace}/fetch`, this.dataIdList);
      }
    },
    inUse() {
      let display = this.mode == 'editor' ? this.$store.getters["dashboard/draft"]?.template || {} : this.display;
      return this.$utils.deepValueSearch(
        display?.panels || [],
        "namedQuery",
        this.namespace
      );
    },
    setup() {
      if (this.ready || !this.inUse()) return;
      // console.log("setup");
      createHistory(this.$store, this.namespace);
      this.$store.dispatch(`${this.namespace}/setNamedQuery`, this.config);
      this.ready = true;
    }
  },
  render() {
    return [];
  },
  beforeDestroy() {
    if (this.mode == 'editor') {
      this.$root.$off("namedQuery:applied");
    }
    destroyHistory(this.$store, this.namespace);
  },
  created() {
    if (this.mode == 'editor') {
      this.$root.$on("namedQuery:applied", (name) => {
        if (!name || name != this.namespace || this.ready) return;
        this.setup();
      });
    }
  },
  beforeCreate() {
    this._onFilterChange = debounce(() => {
      this.onFilterChanged();
    }, 500);
  }
};

export default class NamedQueryManager {

  constructor(parent, display) {
    this.list = [];
    this.parent = parent;
    this.display = display;
    this.editorEvents(true);
    this.init();
  }

  destroy() {
    this.list.forEach((i) => {
      i.$destroy();
    });
    this.editorEvents(false);
    return null;
  }

  async init() {
    if (!this.parent || !this.display) return;
    (this?.display?.namedQueries || []).forEach((config) => {
      this.push(config);
    });
  }

  push(config) {
    const cmp = Vue.component("NamedQuery", Component);
    this.list.push(new cmp({ parent: this.parent, propsData: { display: this.display, config: config } }));
  }

  editorEvents(enabled) {
    // console.log(`${this.parent.$store.getters["dashboard/mode"]} ${enabled}`);
    if (enabled) {
      // event listeners
      this._onRemove = (name) => {
        // console.log("onRemove");
        if (!name) return;
        const ix = this.list.findIndex(({ namespace }) => namespace == name);
        if (ix >= 0) {
          let item = this.list.splice(ix, 1)[0];
          item.$destroy();
          item = null;
        }
      };

      this._onChange = (config) => {
        // console.log("onChange");
        if (!config?.name) return;
        this._onRemove(config.name);
        this.push(config);
      };
      this.parent.$root.$on("namedQuery:changed", this._onChange);
      this.parent.$root.$on("namedQuery:removed", this._onRemove);
    } else {
      this.parent.$root.$off("namedQuery:changed", this._onChange);
      this.parent.$root.$off("namedQuery:removed", this._onRemove);
    }
  }

}